export default {
  MR: {
    name: 'Mr',
    label: 'Mr',
    value: 'MR'
  },
  MRS: {
    name: 'Mrs',
    label: 'Mrs',
    value: 'MRS'
  },
  MS: {
    name: 'Ms',
    label: 'Ms',
    value: 'MS'
  },
  NA: {
    name: 'Mr/Mrs/Ms',
    label: 'Mr/Mrs/Ms',
    value: 'NA'
  }
};
